import React from "react";
import s from "./AgilityImage.module.css";

const AgilityImage = ({
  url,
  label,
  className,
  imageClassName = "",
  fallbackTextBlob,
  style = {},
  onClick = () => {},
}) => {
  if (!!!url && !!fallbackTextBlob) {
    var re = /<img[^>]+src="https:\/\/([^">]+)/g;
    var results = re.exec(fallbackTextBlob);
    if (!!results && !!results.length) {
      url = results[0].replace(/<img.+src="/g, "");
    }
  }
  if (!!!url) {
    var xurl =
      "https://via.placeholder.com/300/094f82/FFFFFF/?text=No Image Detected";
  }
  return (
    !!url && (
      <figure
        style={style}
        onClick={onClick}
        className={`${s.root} ${className}`}
      >
        <img src={url} className={imageClassName} alt={label} />
      </figure>
    )
  );
};

export default AgilityImage;
