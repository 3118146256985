import React from "react";
import Article from "./../../components/Article";
import AdSlot2020 from "./../../modules/AdSlot2020";
import SectionToggle from "./../../components/SectionToggle";
import s from "./Latestarticles.module.css";

const LatestArticles = (props) => {
  const {
    latestArticles = [],
    oneCol = true,
    title = "Latest Articles",
  } = props;
  return (
    <div>
      <section className={`container grid grid-cols-1 spaced-grid-col`}>
        <SectionToggle sections={[{ title: title }]} />
      </section>
      <div className="spaced-grid-col">
        <section
          className={`container grid grid-cols-1 ${
            oneCol
              ? "md:grid-cols-3 lg:grid-cols-4"
              : "md:grid-cols-2 lg:grid-cols-3"
          } gap-guttersm md:gap-10`}
        >
          <div
            className={`grid col-span-1 md:col-span-2 lg:col-span-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-guttersm md:gap-10`}
          >
            {latestArticles.map((article) => (
              <Article
                size={oneCol ? "sm" : "lg"}
                className={`mb-8`}
                key={article.contentID}
                {...props}
                {...article}
              />
            ))}
          </div>
          <AdSlot2020 adID="tower" />
        </section>
      </div>
    </div>
  );
};

export default LatestArticles;
