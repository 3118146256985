import React, { createRef, useState, useEffect } from "react";
import Article from "../../components/Article";

const AuthorBios = (props) => {
  const [currentAuthor, setCurrentAuthor] = useState(false);
  const [articles, setArticles] = useState([]);

  const getAuthorData = async (_currentAuthorID) => {
    let data = await window._appliedradiology.api
      .getContentItem(_currentAuthorID)
      .then((r) => r.fields);
    setCurrentAuthor(data);

    let articles = await window._appliedradiology.api
      .getArticlesByAuthor(_currentAuthorID)
      .then((r) => r.items);
    setArticles(articles);
  };

  useEffect(() => {
    var p = new URLSearchParams(window.location.search);
    let _currentAuthorID = p.get("authorID");
    getAuthorData(_currentAuthorID);
  }, []);
  return (
    !!currentAuthor && (
      <div>
        <h1 className="text-3xl font-bold font-serif leading-none mb-3 text-blue">
          {currentAuthor.longName}
        </h1>
        <div
          className="prose mb-8"
          dangerouslySetInnerHTML={{ __html: currentAuthor.textblob }}
        ></div>
        <div className="grid grid-cols-3 gap-4">
          {articles.map((article, idx) => {
            return <Article key={idx} {...article} />;
          })}
        </div>
      </div>
    )
  );
};

export default AuthorBios;
