import React from "react";
import SectionToggle from "../../components/SectionToggle";
import ArticlesWithPagination from "./ArticlesWithPagination";

const SearchDetail = (props) => {
  const {
    title,
    defaultExpand = false,
    articleCategories,
    searchSubCategories,
    toggleSearchSubCategory,
  } = props;

  const [expand, setExpand] = React.useState(defaultExpand);
  const getCategory = (title) =>
    articleCategories?.items.find((i) => i.fields.title === title) || [];

  const numSelected = getCategory(title).subCategories?.filter((s) => {
    return searchSubCategories.indexOf(`${s.contentID}`) > -1;
  }).length;

  return (
    <div className="mb-4 pb-4 border-b border-greyLight">
      <label
        onClick={(e) => {
          setExpand(!expand);
          console.log(title);
          console.log(getCategory(title));
          console.log(articleCategories?.items);
        }}
        className="font-bold mb-2 flex justify-between cursor-pointer"
      >
        <div>
          {title}
          <span className=" text-grey text-sm">
            {" "}
            {!!numSelected && `(${numSelected})`}
          </span>
        </div>
        <span>{expand ? "-" : "+"}</span>{" "}
      </label>
      {!!expand &&
        getCategory(title) &&
        getCategory(title).subCategories &&
        getCategory(title).subCategories.map((s, idx) => {
          return (
            s?.fields?.disableInSearchFilter !== "true" && (
              <div key={idx} className="inline-block mr-2">
                <input
                  onChange={(e) => toggleSearchSubCategory(s.contentID)}
                  className="mr-1"
                  name={s.contentID}
                  checked={searchSubCategories.indexOf(`${s.contentID}`) > -1}
                  type="checkbox"
                  key={idx}
                />
                <label
                  onClick={(e) => toggleSearchSubCategory(s.contentID)}
                  htmlFor={s.contentID}
                >
                  {s.fields.title}
                </label>
              </div>
            )
          );
        })}
    </div>
  );
};

const ArticleSearch = (props) => {
  const {
    articleCategories,
    query = {},
    filteredArticles,
    totalFilteredCount,
  } = props;

  const [searchSubCategories, setSearchSubCategories] = React.useState(
    !!query.searchSubCategories ? query.searchSubCategories.split(",") : []
  );

  const toggleSearchSubCategory = (contentID) => {
    let _searchSubCategories = searchSubCategories.map((a) => a);
    if (searchSubCategories.indexOf(`${contentID}`) > -1) {
      _searchSubCategories = _searchSubCategories.filter(
        (i) => i !== `${contentID}`
      );
    } else {
      _searchSubCategories.push(`${contentID}`);
    }
    setSearchSubCategories(_searchSubCategories);
  };

  return (
    <>
      <section className={`container grid grid-cols-1 spaced-grid-col`}>
        {/* <SearchDetail title='Digital Portals' articleCategories={articleCategories} toggleSearchSubCategory={toggleSearchSubCategory} searchSubCategories={searchSubCategories} /> */}
        <SearchDetail
          title="Clinical Departments"
          articleCategories={articleCategories}
          toggleSearchSubCategory={toggleSearchSubCategory}
          searchSubCategories={searchSubCategories}
        />
        <SearchDetail
          title="Imaging Modalities"
          articleCategories={articleCategories}
          toggleSearchSubCategory={toggleSearchSubCategory}
          searchSubCategories={searchSubCategories}
        />
        <SearchDetail
          title="Article Type"
          articleCategories={articleCategories}
          toggleSearchSubCategory={toggleSearchSubCategory}
          searchSubCategories={searchSubCategories}
        />

        <a
          href={`?searchSubCategories=${searchSubCategories.join(",")}`}
          className="block uppercase tracking-wider text-center hover:border-blue hover:text-blue text-orange border border-orange text-sm font-bold py-2 mb-5"
        >
          Filter Articles
        </a>

        {!!filteredArticles && (
          <ArticlesWithPagination
            {...props}
            totalCount={totalFilteredCount}
            articles={filteredArticles}
            searchSubCategories={searchSubCategories}
          />
        )}
      </section>
    </>
  );
};

export default ArticleSearch;
