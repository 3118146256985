import React, { useState, useEffect } from "react";
import { RiSendPlaneLine, RiDownloadLine, RiMarkPenLine } from "react-icons/ri";
import ArticleAuthor from "./ArticleAuthor";

const SharePage = (props) => {
  const {
    title,
    pDFUrl = "#",
    year = "2012",
    volume = "1",
    issue = "1",
    pageNumber = "1",
  } = props;
  const [itemClicked, setItemClicked] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  return (
    <>
      <div className="relative z-30 flex text-blue text-xl">
        <div
          role="button"
          className="share-item p-2 cursor-pointer border-grey border flex justify-center items-center border-r-0  hover:text-orange"
        >
          <RiSendPlaneLine />
          <div
            style={{
              top: "37px",
              width: itemClicked == "cite" ? "300px" : "100%",
            }}
            className="share-item-modal absolute shadow-lg right-0 transform w-full bg-white border border-grey text-base p-4"
          >
            <div className="">
              <div className=" text-grey text-xs">SHARE</div>
              <a
                className="block hover:text-orange"
                href="https://fb.com/share"
                target="_blank"
              >
                Facebook
              </a>
              <a
                className="block hover:text-orange"
                href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
                target="_blank"
              >
                Twitter
              </a>
              <a
                className="block hover:text-orange"
                href="#"
                onClick={(e) => {
                  navigator.clipboard.writeText(currentUrl).then(() => {
                    setItemClicked(false);
                  });
                }}
              >
                Copy Url
              </a>
              <a
                className="block hover:text-orange"
                href={`mailto:?subject=Applied Radiology: ${title}`}
                target="_blank"
              >
                Email
              </a>
            </div>
          </div>
        </div>
        {!!pDFUrl && (
          <div className="share-item p-2 cursor-pointer border-grey border flex justify-center items-center hover:text-orange">
            <RiDownloadLine />
            <div
              style={{
                top: "37px",
                width: itemClicked == "cite" ? "300px" : "100%",
              }}
              className="share-item-modal absolute shadow-lg right-0 transform w-full bg-white border border-grey text-base p-4"
            >
              <div className="">
                <div className=" text-grey text-xs">DOWNLOAD</div>
                <a
                  className="block hover:text-orange"
                  href={pDFUrl?.href || "#"}
                >
                  PDF
                </a>
                {/* <a className="block hover:text-orange" href="https://fb.com/share">HTML</a>
          <a className="block hover:text-orange" href="https://fb.com/share">DOCX</a> */}
              </div>
            </div>
          </div>
        )}
        {!!year && !!volume && !!issue && !!pageNumber && (
          <div
            className={`share-item p-2 cursor-pointer border-grey border flex justify-center items-center ${
              !!pDFUrl && "border-l-0"
            } hover:text-orange`}
          >
            <RiMarkPenLine />
            <div
              style={{
                top: "37px",
                width: itemClicked == "cite" ? "300px" : "100%",
              }}
              className="share-item-modal absolute shadow-lg right-0 transform w-full bg-white border border-grey text-base p-4"
            >
              <div className="">
                {/* <div className=" text-grey text-xs">CITE</div> */}
                {/* <a className="block hover:text-orange" href="https://fb.com/share">BibTeX</a>
          <a className="block hover:text-orange" href="https://fb.com/share">CFF</a>
          <a className="block hover:text-orange" href="https://fb.com/share">DOI</a> */}
                <ArticleAuthor {...props} />
                <a
                  className="block hover:text-orange"
                  href="#"
                  onClick={(e) => {
                    navigator.clipboard
                      .writeText(
                        `${!!props.authorNames && props.authorNames} ${
                          props.title && props.title
                        } Appl Radiol. ${
                          !!props.year &&
                          !!props.volume &&
                          !!props.issue &&
                          !!props.pageNumber &&
                          `${props.year};${props.volume}(${props.issue}):${props.pageNumber}`
                        }`
                      )
                      .then(() => {
                        setItemClicked(false);
                      });
                  }}
                >
                  Copy
                </a>
              </div>
            </div>
          </div>
        )}

        {/* {!!itemClicked && <div style={{ top: '37px', width: itemClicked == 'cite' ? "300px" : "100%" }} className="absolute shadow-lg right-0 transform w-full bg-white border border-grey text-base p-4">
      {itemClicked === 'share' && <div className="">
        <div className=" text-grey text-xs">SHARE</div>
        <a className="block hover:text-orange" href="https://fb.com/share" target="_blank">Facebook</a>
        <a className="block hover:text-orange" href={`https://twitter.com/intent/tweet?url=${currentUrl}`} target="_blank">Twitter</a>
        <a className="block hover:text-orange" href="#" onClick={(e) => {
          navigator.clipboard.writeText(currentUrl).then(() => {
            setItemClicked(false)
          });
        }}>Copy Url</a>
        <a className="block hover:text-orange" href={`mailto:?subject=Applied Radiology: ${title}`} target="_blank">Email</a>
      </div>}

      {itemClicked === 'download' && <div className="">
        <div className=" text-grey text-xs">DOWNLOAD</div>
        <a className="block hover:text-orange" href={pDFUrl?.href || "#"}>PDF</a>
        <a className="block hover:text-orange" href="https://fb.com/share">HTML</a>
        <a className="block hover:text-orange" href="https://fb.com/share">DOCX</a>
      </div>}

      {itemClicked === 'cite' && <div className="">
        <div className=" text-grey text-xs">CITE</div>
        <a className="block hover:text-orange" href="https://fb.com/share">BibTeX</a>
        <a className="block hover:text-orange" href="https://fb.com/share">CFF</a>
        <a className="block hover:text-orange" href="https://fb.com/share">DOI</a>
        <ArticleAuthor {...props} />
        <a className="block hover:text-orange" href="#" onClick={(e) => {
          navigator.clipboard.writeText(`${!!props.authorNames && props.authorNames} ${props.title && props.title} ${props.journalName}. ${!!props.year && !!props.volume && !!props.issue && !!props.pageNumber &&
            `${props.year};${props.volume}(${props.issue}):${props.pageNumber}`} 
              https://crossref.org/10.37549/AR${props.contentID}`).then(() => {
              setItemClicked(false)
            });
        }}>Copy</a>
      </div>}
    </div>} */}
      </div>
      {/* {!!itemClicked && <div onClick={e => setItemClicked(false)} className="fixed top-0 left-0 w-screen h-screen bg-transparent z-20"></div>} */}
    </>
  );
};

export default SharePage;
