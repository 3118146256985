import React, { useEffect, useState, Fragment } from "react";
import Article from "./../../components/Article";
import s from "./ArticleListing2020.css";
import AdSlot2020 from "../AdSlot2020/AdSlot2020";

const ArticlesWithPagination = (props) => {
  const {
    articles = [],
    searchSubCategories = [],
    articleSubCategories = [],
    subCategoryFilter = false,
    shared,
    oneCol,
    skip,
    numberofarticlestoshow,
    totalCount,
  } = props;

  const _page = parseInt(skip) / parseInt(numberofarticlestoshow);
  const numpages = Math.ceil(
    parseInt(totalCount) / parseInt(numberofarticlestoshow)
  );

  const [page, setPage] = useState(_page);
  const [search, setSearch] = useState("");
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setSearch(
      !!params.get("searchSubCategories")
        ? `?searchSubCategories=${params.get("searchSubCategories")}`
        : ""
    );
  }, []);
  const selectedSubcategories = articleSubCategories.items
    .filter((s) => searchSubCategories.indexOf(`${s.contentID}`) > -1)
    .map((s) => s?.fields?.title);

  const Pagination = (props) => (
    <section
      className={`container grid grid-cols-1 md:grid-cols-2 gap-guttersm`}
    >
      <div className="block">
        {selectedSubcategories.length > 0 && (
          <p>
            Showing articles tagged with{" "}
            {selectedSubcategories.map((s, idx) => (
              <Fragment key={idx}>
                <span className="inline font-serif font-bold text-blue">
                  {s}
                </span>{" "}
                {idx < selectedSubcategories.length - 1 && " AND "}
              </Fragment>
            ))}
          </p>
        )}
      </div>
      <div className="flex justify-between md:justify-end w-full mb-4 text-sm content-center items-center">
        <a
          href={`/Articles${!!search ? `${search}&` : "?"}skip=${
            (parseInt(page) - 1) * numberofarticlestoshow
          }`}
          className={`block mb-2 mr-1 py-2 px-3 leading-none rounded-sm uppercase text-sm text-white bg-orange hover:bg-blue
    ${page <= 0 ? "cursor-not-allowed pointer-events-none opacity-50" : ""}
    `}
        >
          PREV
        </a>
        <p className="mx-4 mb-2">
          Showing {page + 1} of {numpages} Pages{" "}
          <span className="text-grey">({totalCount} Articles)</span>
        </p>
        <a
          href={`/Articles${!!search ? `${search}&` : "?"}skip=${
            (parseInt(page) + 1) * numberofarticlestoshow
          }`}
          className={`block mb-2 ml-1 py-2 px-3 leading-none rounded-sm uppercase text-sm text-white bg-orange hover:bg-blue
    ${
      page + 1 >= numpages
        ? "cursor-not-allowed pointer-events-none opacity-50"
        : ""
    }
    `}
        >
          Next
        </a>
      </div>
    </section>
  );

  return (
    <React.Fragment>
      <Pagination />
      <section
        className={`container grid grid-cols-1 ${
          oneCol
            ? "md:grid-cols-3 lg:grid-cols-4"
            : "md:grid-cols-2 lg:grid-cols-3"
        } gap-guttersm`}
      >
        <div
          className={`grid grid-cols-1 ${
            !oneCol
              ? "grid col-span-1 md:col-span-2 lg:col-span-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
              : "grid col-span-1 md:col-span-2 lg:col-span-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-4"
          } gap-guttersm md:gap-10`}
        >
          {articles.map((article) => (
            <Article
              size={oneCol ? "sm" : "lg"}
              className={`${s.singlearticle} mb-8`}
              key={article.contentID}
              shared={shared}
              {...article}
            />
          ))}
        </div>
      </section>
      <Pagination />
    </React.Fragment>
  );
};

export default ArticlesWithPagination;
