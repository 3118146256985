import React from "react";
import { hydrate } from "react-dom";
import App from "./App";
import { AllHtmlEntities } from "html-entities";
import createApi from "./helpers/api";
import config from "./options/agilityOptions";
import filterAssetTags from "./helpers/filterAssetTags";

const entities = new AllHtmlEntities();

window.yyyy = entities.decode(window.xxx);

window._appliedradiology = window._appliedradiology || {};
window._appliedradiology.prodApi = createApi(
  config.agilitycmsConfig.guid,
  config.agilitycmsConfig.productionKey
);
window._appliedradiology.previewApi = createApi(
  config.agilitycmsConfig.guid,
  config.agilitycmsConfig.previewKey
);
window._appliedradiology.api = window._appliedradiology.prodApi;

hydrate(<App {...window.___appProps} />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
