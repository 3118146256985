import React, { useState } from 'react';

const SectionToggle = ({sections, defaultSection=0, toggleSection=(e) => {console.log(e)} }) => {
    const [current, setCurrent] = useState(defaultSection);
    return (<section className="border-b-2 border-greyLight pt-3 mb-5 lg:mb-10">
        {sections.map( (s,idx) => <span style={{transform: `translateY(2px)`}} className={idx === current ? `pb-3 border-b-2 uppercase text-xs border-black inline-block mr-4 font-bold tracking-wider cursor-pointer` : ` inline-block pb-3 mr-4 font-bold tracking-wider text-xs uppercase cursor-pointer hover:border-b-2 hover:border-black`} key={idx} onClick={e => {
                e.preventDefault();
                toggleSection(idx, s);
                setCurrent(idx);
            } }>{s.title}</span> )}
    </section>);
}

export default SectionToggle;