import React, { useEffect, useState } from "react";

const slugify = (str) => {
  return str.toLowerCase().replace(/ /g, "-");
};

const MONTHS = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const Issue = (props) => {
  const {
    title,
    description,
    issuePDF,
    issueURL,
    textblob,
    coverImage,
    setSelectedArticle,
    volume,
    issueNumber,
    supplement="false"
  } = props;
  return (
    <article
      onClick={(e) => {
        e.preventDefault();
        setSelectedArticle(props);
        const url = new URL(location);
        url.searchParams.set("volume", volume);
        url.searchParams.set("issue", issueNumber);
        url.searchParams.set("supplement", supplement);
        history.pushState({}, "", url);
      }}
      className="w-full cursor-pointer"
    >
      <a onClick={e => {
        e.preventDefault();
      }} href={`?volume=${volume}&issueNumber=${issueNumber}`}>
        {!!coverImage.url && (
          <img
            className=" w-full block object-contain object-left-bottom"
            style={{
              height: "80%",
            }}
            src={coverImage.url}
            alt={coverImage.label}
          />
        )}
        <h3 className="text-sm">{title}</h3>
      </a>
    </article>
  );
};

const IssueListing = (props) => {
  const { issues, currentPage } = props;
  console.log(issues);
  const latestIssue = issues[0].fields;
  const [selectedArticle, _setSelectedArticle] = useState(false);
  const setSelectedArticle = (a) => {
    _setSelectedArticle(a);
    if (!!a) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    // get url search params from the url for volume, issue and supplement
    const url = new URL(location);
    const volume = url.searchParams.get("volume");
    const issueNumber = url.searchParams.get("issue");
    const supplement = url.searchParams.get("supplement");
    // if there is a volume, issue and supplement, find the issue in the issues array
    console.log(volume, issueNumber, supplement);
    if (volume && issueNumber && supplement) {
      const issue = issues.find(
        (issue) =>
          supplement === "true" ? issue.fields.volume === volume &&
            issue.fields.issueNumber === issueNumber && issue.fields.supplement === supplement :
            issue.fields.volume === volume &&
            issue.fields.issueNumber === issueNumber
        
      );
      console.log(issue);
      if (!!issue) {
        setSelectedArticle(issue.fields);
      }
    }
  }, []);
  return (
    <>
      {!!selectedArticle && (
        <>
          <div
            onClick={(e) => {
              setSelectedArticle(false);
              const url = new URL(location);
              url.searchParams.delete("volume");
              url.searchParams.delete("issue");
              url.searchParams.delete("supplement");
              history.pushState({}, "", url);
            }}
            className="closemodal bg-white bg-opacity-75 fixed top-0 left-0 h-screen w-screen z-10"
          ></div>
          <article className="container absolute top-0 z-50 mt-24 border border-blue p-guttersm lg:p-gutterlg bg-white shadow-lg">
            <div className="flex justify-between w-full">
              <h2 className="text-2xl font-serif font-bold text-orange">
                {selectedArticle.title}
              </h2>
              <div
                onClick={(e) => setSelectedArticle(false)}
                className="cursor-pointer text-orange hover:text-blue"
              >
                CLOSE
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-guttersm ">
              <div
                className="typeset md:col-span-2"
                dangerouslySetInnerHTML={{
                  __html: selectedArticle.description,
                }}
              ></div>
              <figure className="w-full h-full">
                <img
                  className="w-full h-full object-contain"
                  src={selectedArticle.coverImage.url}
                  alt=""
                />
              </figure>
            </div>
          </article>
        </>
      )}

      <section className="spaced-grid-col">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
          <article
            onClick={(e) => setSelectedArticle(latestIssue)}
            className="cursor-pointer col-span-2 md:row-span-2 lg:col-span-2 lg:row-span-2"
          >
            {!!latestIssue.coverImage.url && (
              <img
                className=" w-full block object-contain object-left"
                style={{
                  height: "80%",
                }}
                src={latestIssue.coverImage.url}
                alt={latestIssue.coverImage.label}
              />
            )}
            <h2 className="text-sm uppercase font-bold">Latest Issue</h2>
            <h3 className="text-2xl mb-6 leading-none">{latestIssue.title}</h3>
          </article>
          {issues.map(
            (issue, idx) =>
              idx > 0 &&
              !!issue.fields && (
                <Issue
                  key={idx}
                  {...issue.fields}
                  setSelectedArticle={setSelectedArticle}
                />
              )
          )}
        </div>
      </section>
    </>
  );
};

export default IssueListing;
