import agility from "@agility/content-fetch";
import _ from "lodash";

const createApi = (guid, apiKey, isPreview = false) => {
  const api = agility.getApi({
    guid: guid,
    apiKey: apiKey,
    isPreview: isPreview,
  });
  return {
    api: api,

    getSitemap: (items) => {
      return Promise.resolve(
        api
          .getSitemapFlat({
            channelName: "website",
            languageCode: "en-us",
          })
          .then((sitemap) => sitemap)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getSitemapNested: () => {
      return Promise.resolve(
        api
          .getSitemapNested({
            channelName: "website",
            languageCode: "en-us",
          })
          .then((sitemap) => sitemap)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getPage: (pageID) => {
      return Promise.resolve(
        api
          .getPage({
            pageID: pageID,
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: 3,
          })
          .then((page) => page)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getContentItem: (contentID, depth = 6) => {
      return Promise.resolve(
        api
          .getContentItem({
            contentID: parseInt(contentID),
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: depth,
          })
          .then((contentItem) => contentItem)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getArticleCategories: (params = {}) => {
      const {
        referenceName = "articlecategories",
        skip = 0,
        filter,
        contentLinkDepth = 5,
        take = 50,
        sortDirection = "DESC",
      } = params;
      return Promise.resolve(
        api
          .getContentList({
            referenceName: referenceName,
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: contentLinkDepth,
            take: take,
            skip: skip,
            filter: filter,
            sort: "properties.itemOrder",
            direction: api.types.SortDirections[sortDirection],
          })
          .then((contentList) => contentList)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getContentList: (params) => {
      const {
        referenceName,
        skip = 0,
        filter,
        contentLinkDepth = 5,
        take = 50,
        sortDirection = "DESC",
      } = params;
      if (!!!referenceName) {
        console.log(" No reference name", params);
        return false;
      }
      return Promise.resolve(
        api
          .getContentList({
            referenceName: referenceName,
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: contentLinkDepth,
            take: take,
            skip: skip,
            filter: filter,
            sort: "properties.itemOrder",
            direction: api.types.SortDirections[sortDirection],
          })
          .then((contentList) => contentList)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getContentListAll: async (params = {}) => {
      const {
        referenceName = "articlesubcategories",
        skip = 0,
        filter,
        contentLinkDepth = 5,
        take = 50,
        sortDirection = "DESC",
      } = params;
      let listDefinition = {
        referenceName: referenceName,
        languageCode: "en-us",
        contentLinkDepth: contentLinkDepth,
        take: 50,
        skip: skip,
        filter: filter,
        sort: "properties.itemOrder",
        direction: api.types.SortDirections[sortDirection],
      };
      let items = await Promise.resolve(
        api
          .getContentList(listDefinition)
          .then((contentList) => contentList)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
      let xitems;
      if (items.totalCount > items.items.length) {
        let diff = items.totalCount - items.items.length;
        let numPages = Math.ceil(diff / 50);
        xitems = await Promise.all(
          Array.from(Array(numPages), (x, i) => {
            return api
              .getContentList({
                ...listDefinition,
                skip: 50 * (i + 1),
              })
              .then((contentList) => contentList)
              .catch((error) => {
                console.log(error.data);
                return;
              });
          })
        );
        return {
          items: _.flatten([
            items.items,
            _.flatten(xitems.map((i) => i.items)),
          ]),
          totalCount: items.totalCount,
        };
      } else {
        return { items: items.items };
      }
    },

    getArticlesByAuthor: (authorID) => {
      return Promise.resolve(
        api
          .getContentList({
            referenceName: "articles",
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: 3,
            take: 5,
            skip: 0,
            filters: [
              {
                property: `fields.authorID`,
                operator: api.types.FilterOperators.LIKE,
                value: `${authorID}`,
              },
            ],
            sort: "properties.itemOrder",
            direction: api.types.SortDirections.DESC,
          })
          .then((contentList) => contentList)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getArticlesByIssue: (issueNumber, volume) => {
      return Promise.resolve(
        api
          .getContentList({
            referenceName: "articles",
            languageCode: "en-us",
            expandAllContentLinks: false,
            contentLinkDepth: 1,
            take: 50,
            skip: 0,
            filters: [
              {
                property: `fields.issue`,
                operator: api.types.FilterOperators.EQUAL_TO,
                value: `${issueNumber}`,
              },
              {
                property: `fields.volume`,
                operator: api.types.FilterOperators.EQUAL_TO,
                value: `${volume}`,
              },
            ],
            sort: "properties.itemOrder",
            direction: api.types.SortDirections.ASC,
          })
          .then((contentList) => contentList)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getArticlesByAuthorName: (authorName) => {
      return Promise.resolve(
        api
          .getContentList({
            referenceName: "articles",
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: 3,
            take: 5,
            skip: 0,
            filters: [
              {
                property: `fields.authorNames`,
                operator: api.types.FilterOperators.LIKE,
                value: `${authorName}`,
              },
            ],
            sort: "properties.itemOrder",
            direction: api.types.SortDirections.DESC,
          })
          .then((contentList) => contentList)
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getArticlesInSubCategory: (id, take = 4, skip = 0) => {
      return Promise.resolve(
        api
          .getContentList({
            referenceName: "articles",
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: 1,
            take: take,
            skip: skip,
            filters: [
              {
                property: `fields.subcategoriesIDs`,
                operator: api.types.FilterOperators.LIKE,
                value: `${id}`,
              },
            ],
            sort: "properties.itemOrder",
            direction: api.types.SortDirections.DESC,
          })
          .then((contentList) => {
            return contentList;
          })
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },

    getArticlesByCombinedSubcategories: (params) => {
      const { ids, take = 50, skip = 0 } = params;
      const filters = ids.map((id) => {
        return {
          property: `fields.subcategoriesIDs`,
          operator: api.types.FilterOperators.LIKE,
          value: `${id}`,
        };
      });
      return Promise.resolve(
        api
          .getContentList({
            referenceName: "articles",
            languageCode: "en-us",
            expandAllContentLinks: true,
            contentLinkDepth: 1,
            take: take,
            skip: skip,
            filters: filters,
            filtersLogicOperator: api.types.FilterLogicOperators.AND,
            sort: "properties.itemOrder",
            direction: api.types.SortDirections.DESC,
          })
          .then((contentList) => {
            return contentList;
          })
          .catch((error) => {
            console.log(error.data);
            return;
          })
      );
    },
  };
};

export default createApi;
