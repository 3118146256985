import React from "react";
import Article from "./../../components/Article";
import SectionToggle from "../../components/SectionToggle";
import AdSlot2020 from "../AdSlot2020/AdSlot2020";

const CuratedArticles2019 = (props) => {
  const {
    titleOverride,
    articles = [],
    disableImage = "true",
    disableAd = false,
  } = props;
  const disableImageBool = disableImage === "true";
  const showAd = disableAd !== "true";
  return (
    !!articles &&
    !!articles.length > 0 && (
      <div>
        <section className={`container grid grid-cols-1 spaced-grid-col`}>
          <SectionToggle
            sections={[{ title: titleOverride }]}
            toggleSection={() => {}}
          />
        </section>
        <div className="spaced-grid-col">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-guttersm">
            <section
              className={`grid py-5 grid-cols-1
              md:grid-cols-2 lg:grid-cols-3 gap-guttersm md:gap-10 col-span-1 
              xxx
              ${
                showAd
                  ? "md:col-span-2 lg:col-span-3"
                  : "md:col-span-3 lg:col-span-4"
              }
                `}
            >
              {articles.map((article, idx) => (
                <Article
                  key={idx}
                  size="sm"
                  className={`mb-8`}
                  {...props}
                  {...article}
                  supressImage={disableImageBool}
                />
              ))}
            </section>
            {showAd && (
              <div className=" col-span-1">
                <AdSlot2020
                  adID={
                    (articles.length > 3 && !disableImageBool) ||
                    (articles.length > 6 && disableImageBool)
                      ? "tower"
                      : "default"
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default CuratedArticles2019;
