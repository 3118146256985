import React, { useState } from "react";
import Article from "./../../components/Article";
import SectionToggle from "./../../components/SectionToggle";
import s from "./ArticleListing2020.css";
import ArticleSearch from "./ArticleSearch";
import ArticlesWithPagination from "./ArticlesWithPagination";

const ArticleListing2020 = (props) => {
  const [section, setSection] = useState(!!props.totalFilteredCount ? 1 : 0);
  return (
    <React.Fragment>
      <section className={`container grid grid-cols-1 spaced-grid-col`}>
        <SectionToggle
          defaultSection={section}
          toggleSection={setSection}
          sections={[{ title: "All Articles" }, { title: "Filter Articles" }]}
        />
      </section>
      <div
        className={`${
          section === 0
            ? "container grid grid-cols-1 spaced-grid-col"
            : "hidden"
        }`}
      >
        <ArticlesWithPagination {...props} articles={props.latestArticles} />
      </div>
      <div className={`${section === 1 ? "block" : "hidden"}`}>
        <ArticleSearch {...props} />
      </div>
    </React.Fragment>
  );
};

export default ArticleListing2020;
