import React from 'react'

const ArticleAuthor = (props) => {
    const {
        authorNames,
        title,
        year,
        journalName,
        volume,
        issue,
        pageNumber,
        contentID
    } = props
    return !!year && !!volume && !!issue && !!pageNumber && <div className="author-and-citation text-sm p-4 bg-orangeLight">
        {authorNames && authorNames} {title && title} {journalName}.
        <div>
            {year};{volume}({issue}):{pageNumber}
        </div>
        {/* <div className='DOI mt-4'>
            <span className="text-sm">https://crossref.org/10.37549/AR{contentID}</span>
        </div> */}
    </div>
}

export default ArticleAuthor