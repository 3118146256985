import React from 'react';
import findItemFromContentListById from './../../helpers/findItemFromContentListById';
import AdSlot2020 from './../AdSlot2020/AdSlot2020';

const Badge = ({ badges, zoneName, type }) => {
    let currentBadgeTitle = !!badges && !!badges.fields && !!badges.fields.title && badges.fields.title.toLowerCase().indexOf('tower') > -1 ? 'tower' : 'default';
    const divId = badges?.fields?.badge.search(/(?=id=")id="(.*)"\s/);
    const divIdValue = divId > -1 ? badges.fields.badge.substring(divId + 4, badges.fields.badge.indexOf('"', divId + 4)) : '';
    const _Badges = {
        default: {
            desktop: `https://via.placeholder.com/300x250.png?text=ADVERTISEMENT%20PLACEHOLDER`,
            tablet: `https://via.placeholder.com/300x250.png?text=ADVERTISEMENT%20PLACEHOLDER`,
            mobile: `https://via.placeholder.com/320x50.png?text=ADVERTISEMENT%20PLACEHOLDER`
        },
        tower: {
            desktop: `https://via.placeholder.com/300x600.png?text=AD`,
            tablet: `https://via.placeholder.com/300x600.png?text=AD`,
            mobile: `https://via.placeholder.com/320x50.png?text=AD`
        }
    }
    const theBadge = _Badges[currentBadgeTitle];
    return (<div className="badge my-4 unspace-grid-col">
        <AdSlot2020 divId={divIdValue} />
    </div>)
}

export default Badge;