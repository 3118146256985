import React, {useEffect, useState} from 'react';
import Spinner from './../../components/Spinner';
import SectionToggle from './../../components/SectionToggle';

const RecentArticles2019 = (props) => {
    const {categoryIDs} = props;
    const [articles, setArticles] = useState(false);
    useEffect(() => {

    }, [])
    return (<div className="container grid">
        RecentArticles2019
    </div>)
}

export default RecentArticles2019;