import React from "react";

const RichTextArea = ({ textblob, oneCol }) => {
  const replaced = textblob.replace(/style=\".*"/gm, "");
  return (
    <div
      className={`typeset my-10 ${!!oneCol ? "container spaced-grid-col" : ""}`}
      dangerouslySetInnerHTML={{ __html: textblob }}
    />
  );
};

export default RichTextArea;
