import React from "react";
import _ from "lodash";
import AgilityModules from "./AgilityModules";

const ZoneRenderer = ({
  currentPage,
  zone,
  zoneName,
  shared,
  articleCategories,
  articleSubCategories,
}) => {
  const oneCol = currentPage.templateName === "One Column Template";
  return (
    <section
      className={
        oneCol
          ? "col-span-1 md:col-span-3 lg:col-span-4 _sticky self-start top-110"
          : zoneName === "SidebarContentZone"
          ? "ads sticky self-start top-110 col-span-1 spaced-grid-col"
          : "col-span-1 _sticky self-start top-110 md:col-span-2 lg:col-span-3 px-guttersmxxx"
      }
    >
      {zone.map((m, idx) => {
        let CurrentComponent = AgilityModules.Raw;
        if (AgilityModules.hasOwnProperty(m.module)) {
          CurrentComponent = AgilityModules[m.module];
        }
        return (
          <CurrentComponent
            key={idx}
            shared={shared}
            currentPage={currentPage}
            componentName={m.module}
            oneCol={oneCol}
            zoneName={zoneName}
            contentID={m?.item?.contentID}
            articleSubCategories={articleSubCategories}
            articleCategories={articleCategories}
            {...m?.item?.fields}
            {...m?.item?.properties}
          />
        );
      })}
    </section>
  );
};

export default ZoneRenderer;
