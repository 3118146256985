import React, {useState} from "react";

const Raw = (props) => {
    const [open, setOpen] = useState(false);
    return (<pre style={{
        position: 'relative',
        display: "none",
        width: '100%',
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        fontSize: '8px',
    }} onClick={e => setOpen(!open)}>
        <span style={{
            position: "relative",
            display: 'inline-block',
            padding: '4px 6px',
            background: 'yellow',
            opacity: '0.8',
            color: 'orange'
        }}>{props.componentName} is not defined</span>
        <br/>
        {open && JSON.stringify(props)}
    </pre>)
}

export default Raw;