import React, { useState, useRef, useEffect } from "react";
import IframeResizer from "iframe-resizer-react";

const IframeEmbed = ({ iframeSrc }) => {
  const iframeRef = useRef();
  const [iframeHeight, setIframeHeight] = useState("400vh");
  const [iframeWidth, setIframeWidth] = useState(0);

  useEffect(() => {
    if (iframeRef.current) {
    }

    const handleMessage = (ev) => {
      console.log(ev, "eveeve");
      if (ev.data.type && ev.data.type === "resize-iframe") {
        setIframeWidth(ev.data.payload.width);
        setIframeHeight(ev.data.payload.height);
      }
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, [iframeSrc]);

  return (
    <>
      <iframe
        style={{ width: `100%`, height: iframeHeight, minWidth: `100%` }}
        src={iframeSrc}
        ref={iframeRef}
      />
    </>
  );
};

export default IframeEmbed;
