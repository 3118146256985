import React from "react";
import dayjs from "dayjs";
import findItemFromContentListById from "./../helpers/findItemFromContentListById";
import s from "./article.module.css";
import AgilityImage from "./AgilityImage";

const Article = ({
  fields,
  className,
  contentID,
  shared,
  size,
  supressImage = false,
}) => {
  const articleDate = dayjs(fields.date);
  // const subcats = fields.subcategoriesIDs.split(',').map( id => findItemFromContentListById(shared.subCategories, id));
  return (
    <article
      className={`${s.singlearticle} ${size} ${className} rounded-sm ${
        supressImage ? "" : "mb-5"
      }`}
    >
      <a
        className={`${s.imagelink} ${
          !!supressImage ? "" : "border border-blueLight"
        } block mb-4`}
        href={
          !!fields.uRL ? `/Articles/${fields.uRL}` : `/?contentID=${contentID}`
        }
      >
        {!!!supressImage && (
          <AgilityImage
            className="hover:bg-orange rounded-sm overflow-hidden"
            imageClassName="hover:opacity-75"
            {...fields.listingImage}
            fallbackTextBlob={fields.textblob}
          />
        )}
      </a>
      <header className="flex justify-between mt-4">
        <time
          className="text-sm text-grey mb-1"
          dateTime={articleDate.format("YYYY-MM-DD")}
        >
          {articleDate.format("MMM DD, YYYY")}
        </time>
        {/*<div className="categories">{cats.map( c => c.fields.title)}</div>*/}
        {/*<div className="text-sm text-orange">{subcats[0].fields.title}</div>*/}
      </header>
      <h1 className={s.heading}>
        <a
          href={
            !!fields.uRL
              ? `/Articles/${fields.uRL}`
              : `/?contentID=${contentID}`
          }
        >
          {fields?.articleTitle || fields.title}
        </a>
      </h1>
      {!!fields.authorNames && (
        <p className="text-sm mt-1 text-grey">By {fields.authorNames}</p>
      )}
    </article>
  );
};

export default Article;
