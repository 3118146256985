import React, { useState } from "react";
import Article from "./../../components/Article";
import s from "./../LatestArticles/Latestarticles.module.css";

const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

const CategoryHighlight2020 = (props) => {
  const { categories, anchorLink, versionID } = props;
  const [current, setCurrent] = useState(0);

  const categoryParents = categories
    .map((c) => c.fields.parentCategoryTitle)
    .filter((value, idx, self) => self.indexOf(value) === idx);

  const isCommunityHighlight =
    !!categoryParents &&
    categoryParents.length === 1 &&
    categoryParents[0] === "Digital Portals";

  return (
    <section
      id={anchorLink || versionID}
      className="lg:grid lg:grid-cols-4 lg:gap-10 px-10 my-10 border-t border-b border-blueLight py-10 relative"
    >
      {!!isCommunityHighlight && (
        <h3 className="bg-white block mx-auto absolute top-0 left-0 right-0 text-center w-64 transform -translate-y-5 font-serif font-semibold text-2xl text-blue">
          Digital Portals
        </h3>
      )}
      <div className="lg:col-span-1 lg:text-right w-full max-w-full flex-no-wrap">
        {categories.map(({ articles, fields }, idx) => {
          return (
            <div
              key={idx}
              className={`font-serif leading-tight font-semibold cursor-pointer hover:text-blue inline-block lg:block lg:pr-4 lg:py-5 text-xl mr-3  lg:text-2xl lg:border-r-2 ${idx === current
                  ? "border-blue text-blue"
                  : "border-blueLight text-orange"
                }`}
              onClick={(e) => setCurrent(idx)}
            >
              {fields.title.replace(/Community/g, "")}
            </div>
          );
        })}
      </div>
      <div className="col-span-3">
        <section
          className={`grid py-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-guttersm md:gap-10`}
        >
          {!!categories[current] && !!categories[current].articles &&
            categories[current].articles.items.map((article, idx) => (
              <Article
                size="sm"
                className={` mb-8`}
                key={idx}
                {...props}
                {...article}
              />
            ))}
        </section>
        {!!categories[current] &&
          categories[current].fields.sponsorLogo &&
          categories[current].fields.sponsorLogo.url && (
            <div className="pl-10 mb-10 flex justify-center items-center tracking-wider">
              <span className="text-xs font-bold mr-2 text-grey uppercase">
                {categories[current].fields.title} IS BROUGHT TO YOU BY{" "}
              </span>
              <img
                style={{
                  height: "auto",
                  width: "100%",
                  maxWidth: "100px",
                }}
                src={categories[current].fields.sponsorLogo.url}
              />
            </div>
          )}
        <a
          href={`/digital-portals/${slugify(
            categories[current].fields.title.replace(/Community/g, "")
          )}`}
          className="block uppercase tracking-wider text-center hover:border-blue hover:text-blue text-orange border border-orange text-sm font-bold py-2"
        >
          GO TO {categories[current].fields.title}
        </a>
      </div>
    </section>
  );
};

export default CategoryHighlight2020;
