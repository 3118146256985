import React from "react";

const QuickLinks = ({ title, alllinks }, idx) => {
  return (
    <div
      key={idx}
      className="text-center  text-blue border-t border-b border-blue py-3 mb-10 mx-10 leading-none tracking-wide"
    >
      <span className="text-xs title px-3 uppercase text-black font-bold">
        {title}
      </span>
      {alllinks.reverse().map(({ fields }, idx) => {
        const { link } = fields;
        {
          /* return <a className="px-3 text-sm uppercase hover:text-orange font-semibold tracking-wider" href={link.href}>{link.text}</a> */
        }
        return (
          !!link?.href && (
            <a
              key={idx}
              className="px-3 text-sm uppercase hover:text-orange font-semibold tracking-wider"
              href={link?.href}
            >
              {link.text}
            </a>
          )
        );
      })}
    </div>
  );
};

export default QuickLinks;
