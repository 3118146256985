import React from "react";
import AdSlot2020 from "./../AdSlot2020";

const RelatedMaterials2019 = (props) => {
  const { relatedMaterials, titleOverride } = props;
  return (
    <section>
      <section className="mb-4 mx-4 lg:mx-10 border-b-2 border-greyLight">
        <span
          style={{
            transform: `translateY(2px)`,
          }}
          className="pb-3 border-b-2 uppercase text-xs border-black inline-block mr-4 font-bold tracking-wider cursor-pointer"
        >
          {!!titleOverride ? titleOverride : "RELATED MATERIALS"}
        </span>
      </section>
      <div className="grid grid-cols-4 gap-5 mb-4 mx-4 lg:mx-10 ">
        {relatedMaterials.map((item, idx) => {
          const { link, subtitle, thumbnailImage, title } = item.fields;
          return (
            <a
              href={link.href}
              target={link.target}
              className="block"
              key={idx}
            >
              {!!thumbnailImage && (
                <img className="block" src={thumbnailImage.url} />
              )}
              <span className="text-grey text-xs tracking-wide font-semibold uppercase">
                {title}
              </span>
              <h4 className="font-bold">{subtitle}</h4>
            </a>
          );
        })}
        <AdSlot2020 />
      </div>
    </section>
  );
};

export default RelatedMaterials2019;
