import React from "react";
import "./App.global.css";
import styles from "./app.module.css";
import ZoneRenderer from "./ZoneRenderer";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AdSlot2020 from "./modules/AdSlot2020/AdSlot2020";
import dayjs from "dayjs";

const App = ({
  currentPage,
  headerNav,
  currentSitemapItem,
  shared,
  articleCategories,
  articleSubCategories,
}) => (
  <React.Fragment>
    <br />
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AdSlot2020
        className="hidden lg:flex"
        divId="div-gpt-ad-1588943707960-0"
        adID="superleaderboard"
      />
      <AdSlot2020
        className="hidden md:flex"
        divId="div-gpt-ad-1427466002495-2"
        adID="superleaderboard"
      />
      <AdSlot2020
        className="md:flex"
        divId="div-gpt-ad-1457549217687-0"
        adID="superleaderboard"
      />
    </div>
    <Header headerNav={headerNav} />
    <div className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
      {!!currentPage &&
        !!currentPage.zones &&
        Object.keys(currentPage.zones).map(
          (zoneName) =>
            !!currentPage.zones[zoneName] &&
            !!zoneName && (
              <ZoneRenderer
                currentPage={currentPage}
                zone={currentPage.zones[zoneName]}
                zoneName={zoneName}
                shared={shared}
                key={zoneName}
                articleSubCategories={articleSubCategories}
                articleCategories={articleCategories}
              />
            )
        )}
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AdSlot2020
        className="hidden lg:flex"
        divId="div-gpt-ad-1588946772826-0"
        adID="superleaderboard"
      />
      <AdSlot2020
        className="hidden md:flex"
        divId="div-gpt-ad-1427466002495-1"
        adID="superleaderboard"
      />
      <AdSlot2020
        className="md:flex"
        divId="div-gpt-ad-1457549509310-0"
        adID="superleaderboard"
      />
    </div>
    {/* <div>
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1531763234521-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1427466002495-5" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1427466002495-6" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1427466002495-7" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1460479930053-2" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1460479930053-1" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1460479930053-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1539192663719-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1460479930053-3" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1528212725689-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1528212893153-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1528212962958-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1528208893973-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1427466002495-2" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1427466002495-1" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1427466002495-2" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1427466002495-1" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1457549217687-0" />
      <AdSlot2020 adID="tower" divId="div-gpt-ad-1457549509310-0" />
    </div> */}
    <Footer />
    <div className="container grid grid-cols-1 md:grid-cols-2 py-guttersm px-guttersm lg:px-gutterlg text-grey text-sm">
      <div className="mb-2 lg:mb-0">
        &copy; Anderson Publishing, Ltd. {dayjs().format("YYYY")} All rights
        reserved. Reproduction in whole or part without express written
        permission Is strictly prohibited.{" "}
      </div>
      <div className=" lg:flex flex-row-reverse">
        <a className="lg:mx-2" href="/terms-and-conditions">
          Terms and Conditions
        </a>
        <a className="lg:mx-2" href="/privacy-policy">
          Privacy Policy
        </a>
      </div>
    </div>
  </React.Fragment>
);

export default App;
