import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import algoliasearch from "algoliasearch";
import { cloneDeep } from "lodash";
const algoliaClient = algoliasearch(
  process.env.RAZZLE_ALGOLIA_APP_ID,
  process.env.RAZZLE_ALGOLIA_SEARCH_API_KEY
);
const algolia = algoliaClient.initIndex(process.env.RAZZLE_ALGOLIA_INDEX);

const SearchResultsAlgolia = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [results, setResults] = useState([]);
  const [nextPage, setNextPage] = useState(0);

  const getData = async (searchQuery) => {
    if (!!!searchQuery && !!!searchTerm) {
      return false;
    }
    setIsFetchingData(true);
    const term = searchQuery ? searchQuery : searchTerm;
    await algolia
      .search(term, { page: nextPage })
      .then(({ hits, hitsPerPage, page }) => {
        let _hits = [...cloneDeep(results), ...hits];
        setNextPage(page + 1);
        setIsFetchingData(false);
        setResults(_hits);
      })
      .catch((err) => {
        setIsFetchingData(false);
        console.log({ err });
      });
  };

  // useEffect(() => {
  //   getData();
  // }, [searchTerm]);

  useEffect(() => {
    // get search query from url of value of s param and set it to searchTerm
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get("s");
    if (!!searchQuery) {
      setSearchTerm(searchQuery);
      getData(searchQuery);
    }
  }, []);

  return (
    <div className="px-guttersm lg:px-gutterlg ">
      {!!isFetchingData && (
        <div
          className="fixed top-0 left-0 w-screen h-screen bg-blueLight bg-opacity-75"
          style={{
            zIndex: "11111",
          }}
        >
          <Spinner />
        </div>
      )}
      <h2 className="text-xl mb-6 font-semibold">
        {!!searchTerm && !!results.length
          ? `${results.length > 0 ? "Results" : "Search"} for ${searchTerm}`
          : `Search for ${
              !!searchTerm ? searchTerm : "a keyword, topic or tag"
            }`}
      </h2>
      <form className="flex">
        <input
          className="border border-grey block w-full text-sm px-5 py-2 focus:outline-none"
          placeholder="Enter Search Term"
          type="text"
          value={searchTerm}
          onChange={(e) => {
            if (!e.target.value) {
              setResults([]);
              setNextPage(0);
            }
            setSearchTerm(e.target.value);
          }}
        />
        <button
          className="bg-blue text-sm px-5 py-2 uppercase tracking-wider block text-white"
          onClick={(e) => {
            e.preventDefault();
            // setResults([]);
            // setNextPage(0);
            // if (!!searchTerm) {
            //   getData();
            // }
            window.location.href = `/search-results?s=${searchTerm}`;
          }}
        >
          Search
        </button>
      </form>
      {!!results && !!results.length && (
        <div className="my-4">
          <h3 className="text-lg font-bold mb-2">Results ({results.length})</h3>
          {results.map((r, i) => (
            <a
              href={`/?contentID=${r.objectID}`}
              className="block mb-4 searchresult"
            >
              <h4
                className="text-lg font-bold text-blue"
                dangerouslySetInnerHTML={{
                  __html: r._highlightResult.title.value,
                }}
              ></h4>
              <p className="text-base">{r.authorName}</p>
              {!!r?._snippetResult?.textblob?.value && (
                <div
                  className="text-base"
                  dangerouslySetInnerHTML={{
                    __html: "..." + r?._snippetResult?.textblob?.value + "...",
                  }}
                ></div>
              )}
              {/* {!!r.subcategories &&
                r.subcategories.indexOf("News Briefs") === -1 && (
                  <div className="DOI">
                    <span className="text-sm">
                      https://crossref.org/10.37549/AR{r.objectID}
                    </span>
                  </div>
                )} */}
            </a>
          ))}
        </div>
      )}
      {!!results && results.length > 0 && (
        <div>
          <button
            onClick={(e) => {
              getData();
            }}
          >
            ShowMore
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchResultsAlgolia;
