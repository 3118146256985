import React, { useEffect, useState } from 'react';
import Spinner from './../../components/Spinner';
import Carousel from 'nuka-carousel';

const ImageSlider = (props) => {
    const { articleCategories, articleSubCategories, sliderItems, slideritems } = props;
    const [slides, setSlides] = useState([]);
    useEffect(() => {
        _appliedradiology.api.getContentList({referenceName: sliderItems.referencename})
        .then(data => {
            setSlides(data.items);
        })
    }, []);
    return (<section className="slider spaced-grid-col mb-10">
        <Carousel
            autoplay={true}
            autoplayInterval={3000}
            autoplayReverse={true}
            renderCenterLeftControls={(props) => {
                const {previousSlide, currentSlide} = props;
                return (
                <button className={`block bg-white shadow-2xl outline-none border-0 border-b-2 border-orange px-4 py-2 appearance-none text-sm uppercase ${currentSlide === 0 ? 'hidden cursor-not-allowed' : 'cursor-pointer hover:text-semibold'}`} onClick={previousSlide}>Previous</button>
            )}}
            renderCenterRightControls={({ nextSlide, slideCount, currentSlide }) => (
                <button className={`block bg-white shadow-2xl outline-none border-0 border-b-2 border-orange px-4 py-2 appearance-none text-sm uppercase ${currentSlide+1 === slideCount ? 'hidden cursor-not-allowed' : 'cursor-pointer hover:text-semibold'}`} onClick={nextSlide}>Next</button>
            )}
        >
            {slides.map(slide => <img key={slide.contentID} src={slide.fields.image.url} title={slide.fields.title} />)}
        </Carousel>
    </section>);
}

export default ImageSlider;