import React, { useEffect, useState } from "react";
import Carousel from "nuka-carousel";
import AdSlot2020 from "./../../modules/AdSlot2020";

const Slider2020 = ({
  allsides = [],
  articleLinks,
  articleSlides,
  showAd,
  adDivId,
  currentIssue,
  currentIssueArticles,
}) => {
  const combinedSlides = [
    ...articleSlides.map(({ fields }) => {
      const {
        title = "",
        link = { url: "" },
        listingImage = { url: "" },
        imageGallery,
        uRL,
      } = fields;
      return {
        fields: {
          ...fields,
          uRL,
          title,
          link,
          featuredImage:
            !!imageGallery && !!imageGallery.media
              ? imageGallery.media[0]
              : listingImage,
        },
      };
    }),
    ...allsides,
  ];
  console.log(currentIssueArticles?.items.map((f) => f.fields));
  return (
    <section className="spaced-grid-col mb-10 ">
      <div className="grid gap-10 grid-cols-1 lg:grid-cols-4">
        <div className="col-span-3 slider" style={{ backgroundColor: "black" }}>
          <Carousel
            autoplay={true}
            wrapAround={true}
            autoplayInterval={30000}
            autoplayReverse={false}
            renderCenterLeftControls={(props) => {
              const { previousSlide, currentSlide } = props;
              return false;
              return (
                <button
                  className={` bg-white shadow-2xl outline-none border-0 border-b-2 border-orange px-4 py-2 appearance-none text-sm uppercase ${
                    currentSlide === 0
                      ? "hidden cursor-not-allowed"
                      : "hidden lg:block cursor-pointer hover:text-semibold"
                  }`}
                  onClick={previousSlide}
                >
                  Previous
                </button>
              );
            }}
            renderCenterRightControls={({
              nextSlide,
              slideCount,
              currentSlide,
            }) =>
              true ? (
                false
              ) : (
                <button
                  className={` bg-white shadow-2xl outline-none border-0 border-b-2 border-orange px-4 py-2 appearance-none text-sm uppercase ${
                    currentSlide + 1 === slideCount
                      ? "hidden cursor-not-allowed"
                      : "hidden lg:block cursor-pointer hover:text-semibold"
                  }`}
                  onClick={nextSlide}
                >
                  Next
                </button>
              )
            }
          >
            <div
              style={{
                minHeight: `400px`,
                maxHeight: `600px`,
              }}
              className={`relative block w-full h-full bg-black rounded-sm text-white`}
            >
              <img
                className="h-full w-full pointer-events-none absolute top-0 left-0 object-right object-contain -z-10"
                src={currentIssue?.fields?.coverImage?.url}
              />
              <div className="relative z-10 my-4 max-w-sm px-4 h-full bg-black">
                <h2 className="text-white text-xl">
                  {currentIssue?.fields.title}
                </h2>
                {!!currentIssueArticles?.items &&
                  currentIssueArticles?.items
                    ?.filter((a) => {
                      return a?.fields?.supplementaryArticle !== "true";
                    })
                    .map((a) => {
                      const journalName = a?.fields?.journalName || "";
                      const isSupplement =
                        journalName.indexOf("Supplement") > -1;
                      return (
                        !isSupplement && (
                          <a
                            href={`/Articles/${a.fields.uRL}`}
                            className="block mt-2 text-xs hover:text-orange"
                          >
                            • {a.fields.title}
                          </a>
                        )
                      );
                    })}
              </div>
            </div>
            {combinedSlides &&
              combinedSlides.length &&
              combinedSlides.map(({ fields }, idx) => {
                const {
                  title,
                  theme = "light",
                  featuredImage = { url: "" },
                  uRL,
                } = fields;
                return (
                  <React.Fragment key={idx}>
                    <a
                      href={`/Articles/${uRL}`}
                      className={`relative block w-full rounded-sm ${
                        theme === "dark"
                          ? "text-orange bg-black"
                          : "text-blue bg-white"
                      }`}
                      style={{
                        minHeight: `400px`,
                        maxHeight: `600px`,
                      }}
                    >
                      <img
                        className="block h-full w-full object-cover object-center rounded-sm overflow-hidden"
                        src={featuredImage.url}
                      />
                      <div className="text md:absolute top-0 h-full w-full text-left right-0 left-0 flex items-center py-5 mx-auto">
                        <div
                          className="item bg-opacity-75 max-w-md lg:mt-30 py-10 bg:black md:bg-white md:bg-opacity-75 px-10 md:shadow-2xl rounded-sm md:ml-8"
                          style={{
                            backdropFilter: `blur(6px)`,
                          }}
                        >
                          <h2 className="text-lg lg:text-2xl font-serif font-bold leading-tight">
                            {title}
                          </h2>
                        </div>
                      </div>
                    </a>
                  </React.Fragment>
                );
              })}
          </Carousel>
        </div>
        <div className="col-span-1 flex flex-col justify-between">
          {showAd === "true" && !!adDivId ? (
            <AdSlot2020 divId={adDivId} adID="tower" />
          ) : (
            articleLinks.map((article, idx) => {
              const { fields, contentID } = article;
              return (
                <div key={idx}>
                  <a
                    className="block font-bold tracking-wide text-sm hover:text-orange"
                    href={`/?contentID=${contentID}`}
                  >
                    {fields.title}
                  </a>
                  <span className="text-xs">By {fields.authorName}</span>
                </div>
              );
            })
          )}
        </div>
      </div>
    </section>
  );
};

export default Slider2020;
