import _ from "lodash";
import url from "url";

const ADSLOTIDs = {
  tower: [
    "div-gpt-ad-1460479930053-3",
    "div-gpt-ad-1528212725689-0",
    "div-gpt-ad-1528212962958-0",
    "div-gpt-ad-1528212893153-0",
    "div-gpt-ad-1528208893973-0",
  ],
  badge: [
    "div-gpt-ad-1427466002495-5",
    "div-gpt-ad-1427466002495-6",
    "div-gpt-ad-1427466002495-7",
    "div-gpt-ad-1460479930053-2",
    "div-gpt-ad-1460479930053-1",
    "div-gpt-ad-1460479930053-0",
    "div-gpt-ad-1539192663719-0",
  ],
};

const AdModuleProps = {
  module: "AdSlot2020",
  item: {
    contentID: 15034,
    properties: {
      state: 2,
      modified: "2022-11-18T09:33:04.867",
      versionID: 93003,
      referenceName: "issues_adslot2020",
      definitionName: "AdSlot2020",
      itemOrder: 0,
    },
    fields: {
      adID: "tower",
    },
  },
};

const AgilityOptions = {
  agilityMiddlewareOptions: {
    error500: "/errors/500",
    error404: "/errors/404",
    home: "/homepage",
    zoneHandlers: {
      SidebarContentZone: async function (
        zone,
        agility,
        sitemapNested,
        thisSiteMapItem,
        allZoneKeys
      ) {
        let _zone = zone.map((z) => z);
        _zone = ADSLOTIDs.tower.map((xadDivId, idx) => {
          const ad = { ..._.cloneDeep(AdModuleProps), injected: true };
          ad.item.fields.divId = `${xadDivId}`;
          return ad;
        });
        return _zone;
      },
      MainContentZone: async (
        zone,
        agility,
        sitemapNested,
        thisSiteMapItem,
        allZoneKeys
      ) => {
        // handling auto injection of ads on single content zone
        let towerIdx = 0;
        if (allZoneKeys.length > 1) {
          return zone;
        }
        let _zone = Promise.all(
          zone.map(async (module) => {
            if (module.module === "TabCategories") {
              module.item.fields.adDivId = ADSLOTIDs.tower[towerIdx];
              towerIdx++;
            }
            if (module.module === "CommunityHighlight2020") {
              module.item.fields.adDivId = ADSLOTIDs.tower[towerIdx];
              towerIdx++;
              module.item.fields.adDivIdLatest = ADSLOTIDs.tower[towerIdx];
              towerIdx++;
            }
            if (
              module.module === "Slider2020" &&
              module.item.fields.showAd === "true"
            ) {
              module.item.fields.adDivId = ADSLOTIDs.tower[towerIdx];
              towerIdx++;
            }
            return module;
          })
        );
        return _zone;
      },
    },
    moduleHandlers: {
      ArticleDetails: async (module, agility, currentPage) => {
        if (!!currentPage?.thisDynamicItem?.fields?.subcategoriesIDs) {
          let catids =
            currentPage.thisDynamicItem.fields.subcategoriesIDs.split(",");
          module.item.fields.categories = await Promise.all(
            catids.map(async (id) => agility.getContentItem(id))
          );
        }
        return module;
      },
      IssueDetails: async (module, agility, currentPage) => {
        const issues = await agility.getContentListAll({
          referenceName: "issues",
          contentLinkDepth: 3,
          sortDirection: "ASC",
        });
        module.item.fields.issues = issues.items.sort((a, b) => {
          const aVolumeAndIssue = Number(
            `${a.fields.volume}${a.fields.issueNumber}`
          );
          const bVolumeAndIssue = Number(
            `${b.fields.volume}${b.fields.issueNumber}`
          );
          return bVolumeAndIssue - aVolumeAndIssue;
        });
        module.item.fields.issues = issues.items;
        return module;
      },
      IssuesListing: async (module, agility, currentPage) => {
        const issues = await agility.getContentListAll({
          referenceName: "issues",
          contentLinkDepth: 3,
          sortDirection: "ASC",
        });
        module.item.fields.issues = issues.items.sort((a, b) => {
          const aVolume = Number(a.fields.volume);
          const bVolume = Number(b.fields.volume);
          const aIssue = Number(a.fields.issueNumber);
          const bIssue = Number(b.fields.issueNumber);
          if (aVolume === bVolume) {
            return bIssue - aIssue;
          } else {
            return bVolume - aVolume;
          }
        });
        // module.item.fields.issues = issues.items;
        return module;
      },
      ArticleListing2020: async (module, agility, currentPage, req) => {
        // let latestArticles = await syncClient.store.getContentList({referenceName:'articles', languageCode:'en-us'});
        var url_parts = url.parse(req.url, true);
        var query = url_parts.query;
        let skip = req?.query?.skip >= 0 ? req.query.skip : 0;
        module.item.fields.skip = skip;
        module.item.fields.query = { ...query };
        let latestArticles = await agility.getContentList({
          referenceName: "articles",
          contentLinkDepth: "1",
          sort: "fields.issue",
          take: module.item.fields.numberofarticlestoshow,
          skip: skip,
        });
        module.item.fields.latestArticles = latestArticles.items;
        module.item.fields.totalCount = latestArticles.totalCount;
        if (!!query.searchSubCategories) {
          let ids = query.searchSubCategories.split(",");
          let filteredArticles =
            await agility.getArticlesByCombinedSubcategories({
              referenceName: "articles",
              ids,
              contentLinkDepth: "1",
              take: module.item.fields.numberofarticlestoshow,
              skip: skip,
            });
          module.item.fields.filteredArticles = filteredArticles.items;
          module.item.fields.totalFilteredCount = filteredArticles.totalCount;
        }
        return module;
      },
      LatestArticles: async (module, agility, currentPage) => {
        console.log("getting latest articles");
        let latestArticles = await agility.getContentList({
          referenceName: "articles",
          contentLinkDepth: "1",
          take: module.item.fields.displayCount,
          skip: 0,
        });
        module.item.fields.latestArticles = latestArticles.items;
        return module;
      },
      CategoryHighlight2020: async (module, agility) => {
        let catids = module.item.fields.categories_ValueField.split(",");
        let items = await Promise.all(
          catids.map(async (id) => agility.getArticlesInSubCategory(id, 3))
        );
        module.item.fields.categories = module.item.fields.categories.map(
          (cat, idx) => {
            cat.articles = items[idx];
            return cat;
          }
        );
        return module;
      },
      CommunityHighlight2020: async (module, agility) => {
        let catids = module.item.fields.category_ValueField.split(",");
        let items = await Promise.all(
          catids.map(async (id) => agility.getArticlesInSubCategory(id, 10))
        );
        module.item.fields.category = module.item.fields.category.map(
          (cat, idx) => {
            cat.articles = items[idx];
            return cat;
          }
        );
        return module;
      },
      QuickLinks: async (module, agility) => {
        let links = await agility.getContentList({
          referenceName: module.item.fields.links.referencename,
          contentLinkDepth: "2",
        });
        module.item.fields.alllinks = links.items;
        return module;
      },
      Slider2020: async (module, agility) => {
        let slides = await agility.getContentList({
          referenceName: module.item.fields.slides.referencename,
          contentLinkDepth: "2",
        });
        if (!!module?.item?.fields?.currentIssue) {
          module.item.fields.currentIssueArticles =
            await agility.getArticlesByIssue(
              module?.item?.fields?.currentIssue?.fields?.issueNumber,
              module?.item?.fields?.currentIssue?.fields?.volume
            );
        }
        module.item.fields.allsides = slides.items;
        return module;
      },
      RelatedMaterials2019: async (module, agility) => {
        let related = await agility.getContentList({
          referenceName: module.item.fields.relatedMaterials.referencename,
          contentLinkDepth: "2",
        });
        module.item.fields.relatedMaterials = related.items;
        return module;
      },
    },
  },
  agilitycmsConfig: {
    guid: "4d443e28-f1bb-4358-8adc-d7eb609a2d0a",
    previewKey:
      "preview.d3afa70649163c7c295d10356cbbb6ceb177cb81431aed7c5493e601f7e4ff88",
    productionKey:
      "fetch.8edf33639f139b4f8e5384fd8d63a8777f41c9851e4cd4244a993c440f5c960e",
  },
};

export default AgilityOptions;
