import React from "react";

const ARBanner = ({
  button,
  title,
  subTitle,
  description,
  logo,
  backgroundImage,
  textColor,
}) => {
  return (
    !!title && (
      <div className="px-5 lg:px-0">
        <div
          className="text-center rounded overflow-hidden border border-blue py-3 mb-10 lg:mx-10 leading-none tracking-wide grid grid-cols-12 gap-5 gap-2 bg-cover px-5 lg:px-10 py-4 lg:py-20 bg-blue justify-center items-center w-auto cursor-pointer"
          style={{
            backgroundImage: `url(${backgroundImage?.url})`,
            backgroundSize: `100%`,
            backgroundPosition: `cover`,
            aspectRatio: `2480 / 864`,
            color: !!textColor ? textColor : "black",
          }}
          onClick={(e) => {
            e.preventDefault();
            if (!!button && !!button.href) {
              window.location.href = button.href;
            }
          }}
        >
          {!!logo && logo.url && (
            <div className="text-xs hidden lg:block col-span-2 pr-8">
              <img src={logo.url} />
            </div>
          )}
          <div className="col-span-12 lg:col-span-7 text-left">
            <div className="">
              {!!logo && logo.url && (
                <div className="text-xs lg:hidden col-span-2 pr-8 mb-2">
                  <img src={logo.url} className=" h-10" />
                </div>
              )}
              {!!title && (
                <div className="text-xs mb-2 font-bold">{subTitle}</div>
              )}
              <div className=" font-serif font-bold text-2xl lg:text-3xl mb-4">
                {title}
              </div>
              {!!description && <p className="text-xs">{description}</p>}
            </div>
          </div>
          {!!button && !!button.href && !!button.text && (
            <div className="flex hidden lg:block items-center col-span-3 text-right justify-end">
              <a
                className="bg-blue text-white uppercase text-base py-4 px-3 rounded leading-none inline-block"
                href={button.href}
              >
                {button.text}
              </a>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default ARBanner;
