import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import SearchResultsAlgolia from "../SearchResultsAlgolia/SearchResultsAlgolia";

const SearchDetail = (props) => {
  const {
    title,
    defaultExpand = true,
    articleCategories,
    searchSubCategories,
    toggleSearchSubCategory,
  } = props;

  const [expand, setExpand] = React.useState(defaultExpand);
  const getCategory = (title) =>
    articleCategories?.items.find((i) => i.fields.title === title) || [];

  const numSelected = getCategory(title).subCategories.filter((s) => {
    return searchSubCategories.indexOf(`${s.contentID}`) > -1;
  }).length;

  return (
    <div className="mb-4 pb-4 border-b border-greyLight">
      <label className="font-bold mb-2 flex justify-between cursor-pointer">
        <div>
          {title}
          <span className=" text-grey text-sm">
            {" "}
            {!!numSelected && `(${numSelected})`}
          </span>
        </div>
      </label>
      {!!expand &&
        getCategory(title).subCategories.map((s, idx) => {
          return (
            <div key={idx} className="inline-block mr-2">
              <input
                onChange={(e) => toggleSearchSubCategory(s.contentID)}
                className="mr-1"
                name={s.contentID}
                checked={searchSubCategories.indexOf(`${s.contentID}`) > -1}
                type="checkbox"
                key={idx}
              />
              <label
                onClick={(e) => toggleSearchSubCategory(s.contentID)}
                htmlFor={s.contentID}
              >
                {s.fields.title}
              </label>
            </div>
          );
        })}
    </div>
  );
};

const SearchResults = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [results, setResults] = useState([]);

  const [searchSubCategories, setSearchSubCategories] = useState([]);

  const toggleSearchSubCategory = (contentID) => {
    let _searchSubCategories = searchSubCategories.map((a) => a);
    if (searchSubCategories.indexOf(`${contentID}`) > -1) {
      _searchSubCategories = _searchSubCategories.filter(
        (i) => i !== `${contentID}`
      );
    } else {
      _searchSubCategories.push(`${contentID}`);
    }
    setSearchSubCategories(_searchSubCategories);
  };

  const { articleCategories, query, filteredArticles, totalFilteredCount } =
    props;

  const getData = async () => {
    if (!!!searchTerm) {
      return false;
    }
    setIsFetchingData(true);
    // let string = `https://appliedradiology.com/search-results?q=${searchTerm}`;
    let string = `https://content-customsearch.googleapis.com/customsearch/v1?q=${searchTerm}&cx=b5faa9e391d4f5aaa&key=AIzaSyAa8yy0GdcGPHdtD083HiGGx_S0vMPScDM`;
    let data = await fetch(string).then((r) => r.json());
    setIsFetchingData(false);
    console.log(data);
  };

  useEffect(() => {
    if (!!window.location.search) {
      console.log("search present");
    }
  }, []);

  return !!isFetchingData ? (
    <Spinner />
  ) : (
    <>
      <SearchResultsAlgolia />
      {/* <br/>
      <h2 className="text-xl mb-6 font-semibold">
        {!!searchTerm && !!results.length
          ? `Results for ${searchTerm}`
          : // : !!searchTerm && !results.length
            // ? `Search for ${searchTerm}`
            "Search for a keyword, topic or tag"}
      </h2>
      <form className="flex">
        <input
          className="border border-grey block w-full text-sm px-5 py-2 focus:outline-none"
          placeholder="Enter Search Term"
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <button
          className="bg-blue text-sm px-5 py-2 uppercase tracking-wider block text-white"
          onClick={(e) => {
            e.preventDefault();
            if (!!searchTerm) {
              getData();
            }
          }}
        >
          Search
        </button>
      </form>
      <div className="my-4">
        <h3 className="text-lg font-bold">
          Restrict your search to the following categories:
        </h3>
        <p>
          Check any of the following sections to restrict search to those. Leave
          unselected to search the entire site.
        </p>
      </div>
      <div className="grid grid-cols-2 mt-4">
        <SearchDetail
          title="Digital Portals"
          articleCategories={articleCategories}
          toggleSearchSubCategory={toggleSearchSubCategory}
          searchSubCategories={searchSubCategories}
        />
        <SearchDetail
          title="Clinical Departments"
          articleCategories={articleCategories}
          toggleSearchSubCategory={toggleSearchSubCategory}
          searchSubCategories={searchSubCategories}
        />
        <SearchDetail
          title="Imaging Modalities"
          articleCategories={articleCategories}
          toggleSearchSubCategory={toggleSearchSubCategory}
          searchSubCategories={searchSubCategories}
        />
        <SearchDetail
          title="Section"
          articleCategories={articleCategories}
          toggleSearchSubCategory={toggleSearchSubCategory}
          searchSubCategories={searchSubCategories}
        />
      </div>
      {!!searchSubCategories.length && (
        <div
          className="underline cursor-pointer"
          onClick={(e) => setSearchSubCategories([])}
        >
          Clear Category Selection
        </div>
      )} */}
    </>
  );
};

export default SearchResults;
