import React, { useState, useEffect } from "react";
import Spinner from "./Spinner";
import logo from "./../assets/logo-new.png";
import s from "./Header.module.css";
import parse, { domToReact } from "html-react-parser";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";

const parserOptions = {
  replace: (domNode) => {
    const { attribs, children, name } = domNode;
    if (!attribs) return;

    if (name === "ul") {
      return (
        <ul data-children={children.filter(({ name }) => name === "li").length}>
          {domToReact(children, parserOptions)}
        </ul>
      );
    }
    if (name === "li") {
      return (
        <li
          data-children={
            children.filter(({ name }) => name === "a" || name === "ul").length
          }
        >
          {domToReact(children, parserOptions)}
        </li>
      );
    }
  },
};

const NavItems = () => {
  return (
    <>
      <li className="mx-2">
        <a className="inline-block pt-2 pb-3 leading-none" href={`/Articles`}>
          Articles
        </a>
      </li>
      <li className="mx-2">
        <a
          className="inline-block pt-2 pb-3 leading-none"
          href={`/Articles?searchSubCategories=10732`}
        >
          Cases
        </a>
      </li>
      <li className="mx-2">
        <a className="inline-block pt-2 pb-3 leading-none" href={`/issues`}>
          Issues
        </a>
      </li>
      <li className="mx-2">
        <a
          className="inline-block pt-2 pb-3 leading-none"
          href={`/2021/homepage#digital-portals`}
        >
          Digital Portals
        </a>
      </li>
      <li className="mx-2">
        <a className="inline-block pt-2 pb-3 leading-none" href="/journal">
          ARConnect
        </a>
        <ul class="">
          <li>
            <a href="/ARConnect/ALZ-Imaging" target="_self">
              ALZ Imaging
            </a>
          </li>
          <li>
            <a href="/ARConnect/RSNA-2020" target="_self">
              RSNA 2020
            </a>
          </li>
          <li>
            <a href="/ARConnect/COVID19" target="_self">
              COVID19
            </a>
          </li>
          <li>
            <a href="/ARConnect/SmartInjectSolutions" target="_self">
              Bracco
            </a>
          </li>
          <li>
            <a href="/ARConnect/Guerbet" target="_self">
              Guerbet
            </a>
          </li>
          <li class="communities-list-item">
            <a href="/ARConnect/Philips" target="_self">
              Philips
            </a>
          </li>
          <li>
            <a href="/ARConnect/Telix" target="_self">
              Telix
            </a>
          </li>
        </ul>
      </li>
      <li className="mx-2">
        <a className="inline-block pt-2 pb-3 leading-none" href="#subscribe">
          Resources
        </a>
      </li>
      <li className="mx-2">
        <a
          className="rounded-sm block text-white bg-orange px-4 leading-none pt-2 pb-3 uppercase text-sm tracking-wide hover:bg-blue"
          href="#subscribe"
        >
          Subscribe!
        </a>
      </li>
    </>
  );
};

const NavDynamicItems = ({ items }) => {
  return (
    <>
      {!!items &&
        items.map((itm, i) => (
          <li className="mx-2" key={i}>
            <a
              className={`${
                itm.fields?.enableBg == "true"
                  ? "rounded-sm block text-white bg-orange px-4 uppercase text-sm tracking-wide hover:bg-blue"
                  : "inline-block"
              } pt-2 pb-3 leading-none`}
              target={itm.fields.url.target}
              href={itm.fields.url.href}
            >
              {itm.fields.title || itm.fields.url.text}
            </a>
            {!!itm.fields.subNavigation &&
              !!itm.fields.subNavigation.items &&
              !!itm.fields.subNavigation.items.length && (
                <ul className="pt-4 px-2 w-full max-w-xs shadow-lg transform md:-translate-x-2 rounded-sm">
                  {itm.fields.subNavigation.items.map((sub, j) => (
                    <li className="pb-4" key={j}>
                      <a
                        target={sub.fields.url.target}
                        href={sub.fields.url.href + ""}
                      >
                        {sub.fields.title || titlesub.fields.url.text}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
          </li>
        ))}
    </>
  );
};

const Header = ({ headerNav }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [nav, setNav] = useState(false);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      typeof window._appliedradiology !== "undefined"
    ) {
      _appliedradiology.api.getContentItem("12137").then(
        (data) => {
          const menu = JSON.parse(
            JSON.stringify(data.fields.mainMenu).replace(/~\//g, "/")
          );
          setNav(menu);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, []);
  return (
    <section className="py-8 bg-white sticky top-0 z-50">
      <div className="container flex justify-between items-center px-guttersm lg:px-gutterlg relative">
        <a className="block w-64" href={`/home`}>
          <img src={logo} alt="" />
        </a>
        <nav className="mainnav flex">
          <ul className="list-none hidden md:flex space-between font-bold uppercase mr-4 text-sm">
            <NavDynamicItems items={headerNav?.items || []} />
          </ul>
          <a
            href="/search-results"
            className="text-sm leading-none uppercase pt-2 pb-3 hidden md:block"
          >
            Search
          </a>
          <button
            className="p-2 border border-grey md:hidden"
            onClick={() => setOpenMenuMobile(!openMenuMobile)}
          >
            <GiHamburgerMenu />
          </button>
          {!!openMenuMobile && (
            <div className="mobile-nav fixed top-0 left-0 w-screen h-screen z-50 p-3 bg-greyLight md:hidden md:z-0">
              <div className="text-right mb-3">
                <button
                  className="p-2 border border-black"
                  onClick={() => setOpenMenuMobile(!openMenuMobile)}
                >
                  <ImCross />
                </button>
              </div>
              <ul className="-mx-2">
                <NavDynamicItems items={headerNav?.items || []} />
              </ul>
              <a
                href="/search-results"
                className="text-sm leading-none uppercase pt-2 pb-3"
              >
                Search
              </a>
            </div>
          )}
          {/* <a onClick={e => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }} className="block hamburger w-6 cursor-pointer text-black hover:text-blue transform transition-transform mt-2">
          <div className={`transform transition-transform duration-100 origin-top-right line l1 h-1 bg-current mb-1 ${!isOpen ? '' : '-rotate-45'}`}></div>
          <div className={`transform transition-transform duration-100 line l2 h-1 bg-current mb-1 ${!isOpen ? '' : 'scale-0'}`}></div>
          <div className={`transform transition-transform duration-100 origin-bottom-right line l3 h-1 bg-current mb-1 ${!isOpen ? '' : 'rotate-45'}`}></div>
        </a> */}
        </nav>
      </div>
      {isOpen && !nav && (
        <nav className="mainnav">
          <Spinner />
        </nav>
      )}
      {isOpen && nav && (
        <nav className={`${s.mainnav} mainnav container grid spaced-grid-col`}>
          {parse(nav, parserOptions)}
        </nav>
      )}
    </section>
  );
};

export default Header;
