import ArticleDetails from "./modules/ArticleDetails";
import ArticleListing2020 from "./modules/ArticleListing2020";
import ARBanner from "./modules/ARBanner";
import AdSlot2020 from "./modules/AdSlot2020";
import Badge from "./modules/Badge";
import ImageSlider from "./modules/ImageSlider";
import LatestArticles from "./modules/LatestArticles";
import Raw from "./modules/Raw";
import RichTextArea from "./modules/RichTextArea";
import RecentArticles2019 from "./modules/RecentArticles2019";
import TabCategories from "./modules/TabCategories";
import Slider2020 from "./modules/Slider2020";
import QuickLinks from "./modules/QuickLinks";
import CategoryHighlight2020 from "./modules/CategoryHighlight2020";
import CommunityHighlight2020 from "./modules/CommunityHighlight2020/CommunityHighlight2020";
import RelatedMaterials2019 from "./modules/RelatedMaterials2019/RelatedMaterials2019";
import IssueListing from "./modules/IssueListing/IssueListing";
import SearchResults from "./modules/SearchResults/SearchResults";
import AuthorBios from "./modules/AuthorBios";
import CuratedArticles2019 from "./modules/CuratedArticles2019";
import IframeEmbed from "./modules/IframeEmbed/IframeEmbed";
const AgilityModules = {
  AuthorBios,
  IframeEmbed,
  ARBanner,
  ArticleDetails,
  ArticleListing2020,
  AdSlot2020,
  Badge,
  CommunityHighlight2020,
  ImageSlider,
  LatestArticles,
  Raw,
  IssuesListing: IssueListing,
  IssueDetails: IssueListing,
  RecentArticles2019,
  RichTextArea,
  TabCategories,
  Slider2020,
  QuickLinks,
  CategoryHighlight2020,
  CuratedArticles2019,
  RelatedMaterials2019,
  SearchResults,
};

export default AgilityModules;
