import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import Article from './../../components/Article'
import SectionToggle from './../../components/SectionToggle';
import Spinner from './../../components/Spinner';
import s from './../LatestArticles/Latestarticles.module.css';
import AdSlot2020 from './../../modules/AdSlot2020'

const TabCategories = (props) => {
    const { shared, categories, adDivId } = props;
    const [currentCat, setCurrentCat] = useState(0);
    const [articles, setArticles] = useState([]);
    const [cats, setCats] = useState(categories.map(c => {
        return {
            contentID: c.contentID,
            title: c.fields.title,
            articles: []
        }
    }));
    useEffect(() => {
        const getArticlesInSubCategory = async (ids) => {
            try {
                const artByCats = await Promise.all(ids.map(id => window._appliedradiology.api.getArticlesInSubCategory(id, 6)));
                let _cats = cloneDeep(cats);
                artByCats.forEach((artByCat, idx) => {
                    _cats[idx].articles = artByCat.items;
                });
                setCats(_cats);
            } catch (error) {
                console.log(error);
            }
        }
        getArticlesInSubCategory(cats.map(c => c.contentID));
    }, []);
    const [current, setCurrent] = useState(false);
    return (<React.Fragment>
        <section className={`container grid grid-cols-1 spaced-grid-col`}>
            <SectionToggle sections={cats} toggleSection={setCurrentCat} />
        </section>
        <div className="spaced-grid-col">
            <section className={`grid py-5 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-guttersm md:gap-10`}>
                {cats[currentCat].articles.map((article, idx) => <Article key={idx} size='sm' className={`mb-8`} key={article.contentID} {...props} {...article} />)}
                {!!adDivId && <AdSlot2020 divId={adDivId} className="col-start-1 row-start-1 row-span-2" adID="tower" />}
            </section>
        </div>
    </React.Fragment>)
}

export default TabCategories;