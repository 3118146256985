import React, { useEffect, useState, useRef } from "react";

import AdSlot2020 from "./../AdSlot2020";
import Article from "../../components/Article";

const CommunityHighlight2020 = (props) => {
  const {
    brightcoveUrl,
    category,
    highlightedArticles,
    vimeoVideoEmbedCode,
    curatedArticlesTitle,
    adDivId,
    adDivIdLatest,
  } = props;
  const [height, setHeight] = useState(300);

  const videoRef = useRef();
  useEffect(() => {
    setHeight(videoRef.current.clientWidth * 0.5625);
  }, []);
  return (
    <>
      <section className="grid px-4 lg:px-10 mb-0 lg:grid-cols-4 gap-4 lg:gap-10">
        <div
          ref={videoRef}
          className={`video lg:col-span-3 ${
            !!vimeoVideoEmbedCode ? "block" : "hidden"
          }`}
        >
          <h1
            className="flex justify-between text-2xl mb-0 leading-none text-blue text-left"
            style={{
              minHeight: `50px`,
            }}
          >
            <div className="font-serif font-bold uppercase">
              {category[0].fields.title}
            </div>
            {category[0].fields?.sponsorLogo &&
              category[0].fields.sponsorLogo.url && (
                <div className="mb-0 pb-5 tracking-wider text-right">
                  <span className="text-xs block font-bold ml-2 text-orange uppercase">
                    BROUGHT TO YOU BY{" "}
                  </span>
                  <img
                    className="inline mx-auto mt-2"
                    style={{
                      height: "auto",
                      width: "100%",
                      maxWidth: "100px",
                    }}
                    src={category[0].fields.sponsorLogo.url}
                  />
                </div>
              )}
          </h1>
          {/* <div class='embed-container'>
        <iframe src="https://players.brightcove.net/96999894001/gca6pGEIr_default/index.html?playlistId=6019630351001" style={{
          border: '0'
        }}></iframe>
      </div> */}
          {!!vimeoVideoEmbedCode && (
            <div
              dangerouslySetInnerHTML={{
                __html: vimeoVideoEmbedCode,
              }}
            />
          )}
        </div>
        <div>
          <AdSlot2020 divId={adDivId} adID="tower" />
        </div>
        <div
          className="items hidden overflow-y-scroll"
          style={{
            height: height + "px",
            overflowY: "scroll",
          }}
        >
          {category.map((cat, idx) => {
            return (
              !!cat && (
                <React.Fragment key={idx}>
                  {cat.fields?.sponsorLogo && cat.fields.sponsorLogo.url && (
                    <div className="mb-10 pb-5 flex items-center tracking-wider border-b border-blue">
                      <span className="text-xs font-bold mr-2 text-orange uppercase">
                        {cat.fields.title} IS BROUGHT TO YOU BY{" "}
                      </span>
                      <img
                        style={{
                          height: "auto",
                          width: "100%",
                          maxWidth: "100px",
                        }}
                        src={cat.fields.sponsorLogo.url}
                      />
                    </div>
                  )}
                  {cat.articles?.items?.map((article, idx) => {
                    const { fields, contentID } = article;
                    return (
                      idx < 6 && (
                        <div key={idx} className="mb-5">
                          <a
                            className="block font-bold tracking-wide text-sm"
                            href={`/?contentID=${contentID}`}
                          >
                            {fields.title}
                          </a>
                          <span className="text-xs">
                            By {fields.authorName}
                          </span>
                        </div>
                      )
                    );
                  })}
                </React.Fragment>
              )
            );
          })}
        </div>
      </section>
      {!!highlightedArticles && !!highlightedArticles.length > 0 && (
        <>
          <section className="mb-4 mt-8 mx-4 lg:mx-10 border-b-2 border-greyLight">
            <span
              style={{
                transform: `translateY(2px)`,
              }}
              className="pb-3 border-b-2 uppercase text-xs border-black inline-block mr-4 font-bold tracking-wider cursor-pointer"
            >
              {curatedArticlesTitle}
            </span>
          </section>
          <div className="spaced-grid-col">
            <section className="container grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-guttersm md:gap-10">
              {highlightedArticles.map((article) => {
                return <Article {...article} supressImage={true} />;
              })}
            </section>
          </div>
        </>
      )}
      <a
        href={`/Articles?searchSubCategories=${category[0].contentID}`}
        className="block mx-4 lg:mx-10 my-10 uppercase border text-center font-bold text-orange hover:text-blue  border-orange hover:border-blue  leading-none p-2"
      >
        READ ALL ARTICLES IN {category[0].fields.title}
      </a>
      {category[0].fields?.sponsorLogo &&
        category[0].fields.sponsorLogo.url && (
          <div className="mb-10 pb-5 tracking-wider text-center">
            <span className="text-xs block font-bold mr-2 text-orange uppercase">
              BROUGHT TO YOU BY{" "}
            </span>
            <img
              className="block mx-auto mt-2"
              style={{
                height: "auto",
                width: "100%",
                maxWidth: "100px",
              }}
              src={category[0].fields.sponsorLogo.url}
            />
          </div>
        )}
      <br />

      <>
        <section className="mb-4 mx-4 lg:mx-10 border-b-2 border-greyLight">
          <span
            style={{
              transform: `translateY(2px)`,
            }}
            class="pb-3 border-b-2 uppercase text-xs border-black inline-block mr-4 font-bold tracking-wider cursor-pointer"
          >
            THE LATEST IN THE <strong>{category[0].fields.title}</strong>
          </span>
        </section>
        <section className="grid px-4 lg:px-10 mb-10 md:grid-cols-4 gap-10">
          <div className="items md:col-span-3">
            <div className="grid md:grid-cols-3 gap-10">
              {category.map((cat) => {
                return cat.articles?.items?.map((article, idx) => {
                  return (
                    idx < 6 && <Article {...article} supressImage={false} />
                  );
                });
              })}
            </div>
          </div>
          {!!adDivIdLatest && <AdSlot2020 divId={adDivIdLatest} adID="tower" />}
        </section>
      </>
    </>
  );
};

export default CommunityHighlight2020;
