import React, { useEffect, useState } from "react";

const AdSlot2020 = ({
  adID = "default",
  className,
  divId,
  style = {},
  ...props
}) => {
  const _Badges = {
    default: {
      desktop: `https://via.placeholder.com/300x250.png?text=AD%20PLACEHOLDER`,
      tablet: `https://via.placeholder.com/300x250.png?text=AD%20PLACEHOLDER`,
      mobile: `https://via.placeholder.com/300x250.png?text=AD%20PLACEHOLDER`,
    },
    superleaderboard: {
      desktop: `https://via.placeholder.com/970x90.png?text=AD%20PLACEHOLDERDESKTOP`,
      tablet: `https://via.placeholder.com/728x90.png?text=AD%20PLACEHOLDERTABLET`,
      mobile: `https://via.placeholder.com/320x50.png?text=AD%20PLACEHOLDERMOBILE`,
    },
    tower: {
      desktop: `https://via.placeholder.com/300x600.png?text=AD`,
      tablet: `https://via.placeholder.com/300x250.png?text=AD%20PLACEHOLDERTABLET`,
      mobile: `https://via.placeholder.com/320x50.png?text=AD`,
    },
  };
  useEffect(() => {
    if (!!divId) {
      console.log("displaying ad for ", divId);
      googletag.cmd.push(function () {
        googletag.display(`${divId}`);
      });
    }
  }, [divId]);
  return (
    !!divId && (
      <div
        id={divId}
        style={{
          width: !!adID && adID === "superleaderboard" ? "100%" : "300px",
          ...style,
        }}
        className={`adslot text-sm mt-0 text-grey lg:px-4 text-center flex items-center justify-start flex-col ${className}`}
      ></div>
    )
  );
};

export default AdSlot2020;
