import React from "react";
import s from "./Footer.module.css";

import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = (props) => {
  return (
    <>
      <section id="subscribe" className={s.subscribe}>
        <div className="container container px-4 lg:px-10 grid grid-cols-1 md:grid-cols-4 gap-guttersm">
          <div className="md:col-span-3">
            <div className="container mb-8">
              <h2 className="grid text-3xl leading-none mb-4 font-serif font-bold text-orange">
                Subscribe
              </h2>
              <p>
                Stay up to date with the latest in Practical Medical Imaging and
                Management with <nobr>Applied Radiology.</nobr>
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-guttersm">
              <div>
                <h3
                  className="font-serif font-bold
              text-2xl"
                >
                  Stay up to date
                </h3>
                <p className="mb-4">
                  Create a new print or digital subscription to{" "}
                  <nobr>Applied Radiology.</nobr>
                </p>
                <a
                  className=" rounded-sm inline-block bg-orange px-4 leading-none pt-2 pb-3 uppercase text-sm tracking-wide hover:bg-blue"
                  target="_blank"
                  href="https://andersonpublishing.dragonforms.com/AR_new"
                >
                  Subscribe!
                </a>
              </div>
              <div>
                <h3
                  className="font-serif font-bold
              text-2xl"
                >
                  Renew / Update
                </h3>
                <p className="mb-4">
                  Renew or update your current subscription to{" "}
                  <nobr>Applied Radiology.</nobr>
                </p>
                <a
                  className=" rounded-sm inline-block bg-orange px-4 leading-none pt-2 pb-3 uppercase text-sm tracking-wide hover:bg-blue"
                  target="_blank"
                  href="https://andersonpublishing.dragonforms.com/AR_renew"
                >
                  Renew / Update
                </a>
              </div>
            </div>
          </div>

          <div className="readmore">
            <h3 className="text-3xl leading-none mb-4 font-serif font-bold text-orange">
              Resources
            </h3>
            <ul>
              <li>
                <a
                  className="hover:text-orange"
                  href="https://andersonpublishing.dragonforms.com/And_emailpref_AR"
                  target="_blank"
                >
                  Email Alert Manager
                </a>
              </li>

              <li>
                <a
                  className="hover:text-orange"
                  href="/Author-Guidelines"
                  target="_self"
                >
                  Author Guidelines
                </a>
              </li>
              <li className="communities-list-item">
                <a href="/Advertise" target="_self">
                  Advertise
                </a>
              </li>
              <li>
                <a
                  className="hover:text-orange"
                  href="/Resources/About-Us"
                  target="_self"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  className="hover:text-orange"
                  href="/contact-us"
                  target="_self"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className={s.root}>
        <div className="container grid">
          <div className="bttcontainer spaced-grid-col py-guttersm">
            <a className={s.backtotop} href="#">
              BACK TO TOP
            </a>
          </div>
          <div className="spaced-grid-col">
            <hr className={`${s.hr}`} />
          </div>
        </div>
        <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 py-gutterlg">
          <div className="spaced-grid-col">
            <h2 className={`${s.heading}`}>Resources</h2>
            <ul className={s.ul}>
              <li>
                <a href="/Author-Guidelines" target="_self">
                  Author Guidelines
                </a>
              </li>
              <li>
                <a href="/Advertise" target="_self">
                  Advertise
                </a>
              </li>
              <li>
                <a href="/contact-us" target="_self">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="spaced-grid-col">
            <h2 className={`${s.heading}`}>Education</h2>
            <ul className={s.ul}>
              <li>
                <a href="http://www.appliedradiology.org" target="_blank">
                  CME Credits
                </a>
              </li>
              <li>
                <a href="http://www.appliedradiology.org" target="_blank">
                  CE Credits
                </a>
              </li>
              <li>
                <a href="http://www.appliedradiology.org" target="_blank">
                  CRA Credits
                </a>
              </li>
            </ul>
          </div>
          <div className="spaced-grid-col">
            <h2 className={`${s.heading}`}>Social</h2>
            <ul className={s.ul}>
              <li className="inline-block text-2xl text-grey mr-3">
                <a href="https://www.facebook.com/AppliedRad" target="_blank">
                  <FaFacebookSquare />
                </a>
              </li>
              <li className="inline-block text-2xl text-grey mr-3">
                <a href="https://twitter.com/Applied_Rad" target="_blank">
                  <FaTwitterSquare />
                </a>
              </li>
              <li className="inline-block text-2xl text-grey mr-3">
                <a href="https://www.linkedin.com/company/appliedradiology" target="_blank">
                  <FaLinkedin />
                </a>
              </li>
              <li className="inline-block text-2xl text-grey mr-3">
                <a href="https://www.instagram.com/applied_rad" target="_blank">
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
